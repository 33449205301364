import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../images/logo.min.svg';
import '../css/header.css';

const Header: React.FC = () => {
  return (
    <header className='header'>
      <div>
        <div className='header-title'>
          <span>Herefordshire Hand Therapy</span> -{' '}
          <small>Independent Occupational Therapist</small>
        </div>
        <div className='header-links'>
          <NavLink
            to='/'
            className='header-link'
            activeClassName='header-link-active'
            exact
          >
            Home
          </NavLink>
          <NavLink
            to='/therapist'
            className='header-link'
            activeClassName='header-link-active'
          >
            Therapist
          </NavLink>
          <NavLink
            to='/services'
            className='header-link'
            activeClassName='header-link-active'
          >
            Services
          </NavLink>
          <NavLink
            to='/contact'
            className='header-link'
            activeClassName='header-link-active'
          >
            Contact
          </NavLink>
        </div>
      </div>
      <img src={logo} alt='Hereford Hand Therapy' className='header-logo' />
    </header>
  );
};

export default Header;
