import React from 'react';
import MediaQuery from 'react-responsive';

import './css/App.css';
import { HashRouter as Router, Route } from 'react-router-dom';
import Home from './pages/Home';
import Therapist from './pages/Therapist';
import Links from './pages/Links';
import Header from './components/Header';
import MobileHeader from './components/MobileHeader';
import Services from './pages/Services';
import Contact from './pages/Contact';
import FlashCards from './pages/FlashCards';
import Invoices from './pages/Invoices';
import Footer from './components/Footer';

const App: React.FC = () => {
  return (
    <div className='App'>
      <Router>
        <MediaQuery maxDeviceWidth={720}>
          <MobileHeader />
        </MediaQuery>
        <MediaQuery minDeviceWidth={721}>
          <Header />
        </MediaQuery>
        <Route exact path='/' component={Home} />
        <Route path='/therapist' component={Therapist} />
        <Route path='/services' component={Services} />
        <Route path='/links' component={Links} />
        <Route path='/contact' component={Contact} />
        <Route path='/flash' component={FlashCards} />
        <Route path='/invoices' component={Invoices} />
        <Footer />
      </Router>
    </div>
  );
};

export default App;
