import React from 'react';
import Seo from '../components/Seo';
import ScrollToTopOnMount from '../components/ScrollToTop';

const Home: React.FC = () => {
  const title = 'Herefordshire Hand Therapy';
  const description =
    'Herefordshire Hand Therapy - Independent Occupational Therapist, Based in Hereford covering Herefordshire and surrounding area. Offering a variety of evidence based treatment techniques and tools used to improve function.';
  return (
    <div>
      <ScrollToTopOnMount />
      <div className='page-wrapper'>
        <Seo title={title} description={description} url='' />

        <div className='content-wrapper'>
          <h1>How can therapy help?</h1>
          <p>
            Hand therapists bridge the gap from medical management of upper limb
            conditions to successful recovery, allowing individuals to regain
            independence in activities of daily living.
          </p>
          <p>
            Herefordshire Hand Therapy takes a holistic approach to upper limb
            assessment and provides client directed, non-operative
            interventions, preventative care and post-surgical rehabilitation
            for a wide variety of upper limb disorders.
          </p>
          <p>
            A variety of evidence based treatment techniques and tools are used
            to improve function, including home exercise programmes, bespoke
            orthotic fabrication, education on joint protection and energy
            conservation, recommendations for adaptive equipment or devices,
            sensory re-education, desensitisation, management of wounds, scars,
            pain and swelling.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;

/* 
	

Also add copyright (c) Herefordshire Hand Therapy 2019
*/
