import React, { useState } from "react";
import Seo from "../components/Seo";
import ScrollToTopOnMount from "../components/ScrollToTop";
import Cards from "../data/FlashCards"
import FlashCard from "../components/FlashCard";
import "../css/flash.css";

const FlashCards: React.FC = () => {
  const [current, setCurrent] = useState(0);

	const title = 'Flash Cards - Herefordshire Hand Therapy';
  const description = 'Herefordshire Hand Therapy - Independent Occupational Therapist, Postoperative management, Injury prevention, Hand and upper limb assessment and treatment, Teaching sessions for trainee doctors, surgeons and occupational health practitioners. Treats Hand Trauma, Postoperative surgical conditions.';
  
  const nextCard = () => {
    if (current < Cards.length) {
      setCurrent(current + 1);
    }
  }
  const prevCard = () => {
    if (current > 0) {
      setCurrent(current - 1);
    }
  }

	return (
		<div className='page-wrapper'>
			<ScrollToTopOnMount/>	
			<Seo title={title} description={description} url='flash' />
			<div className='content-wrapper'>
				<h1>Flash Cards</h1>
        <div>
          <div>Click on the image to reveal the answer</div>
          {
          Cards.map((card, index) => (
            index === current ?
              <FlashCard image={card.image} question={card.question} answer={card.answer} key={index}/>
              : null
          ))
          }
          <div className='flash-nav'>
            <div>
              {current !== 0 ? <button onClick={prevCard} className='flash-nav-button'>Prev</button> : null}
            </div>
            <div>
              {current !== Cards.length-1 ? <button onClick={nextCard} className='flash-nav-button'>Next</button> : null}
            </div>
          </div>
        </div>
				
			</div>
		</div>
	);

};

export default FlashCards;
