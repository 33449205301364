import React, { useState } from 'react';
import "../css/flash.css";

type FlashCardProps = {
    image: string | undefined;
    question: string | undefined;
    answer: string;
}
const FlashCard: React.FC<FlashCardProps> = (props: FlashCardProps) => {
  const [reveal, setReveal] = useState(false);
      
  const toggleReveal = () => {
    setReveal(!reveal);
  }
  const {image, question, answer} = props;
  return (
    <div onClick={toggleReveal} className='flash-card'>
      {
        reveal ?
        <div className='flash-answer'>{answer}</div>
        :
        <div className='flash-question'>
            {image ? <img src={image} alt='' className='flash-image'/> : null}
            {question ? <div>{question}</div> : null}
        </div>
      }
    </div>
  );
}

export default FlashCard;
