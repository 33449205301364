import React from 'react';
import {Helmet} from 'react-helmet';
type SeoProps = {
    title: string;
    description: string;
    url: string;
}

class Seo extends React.Component<SeoProps,{}> {
    public render = () => {
        const {title, description, url} = this.props;
        return (
            <Helmet>
                <title>{title}</title>
                <meta property='og:title' content={ title } />
                <meta property='og:description' content={ description } />
                <meta property='og:type' content='website' />
                <meta property='og:site_name' content='Herefordshire Hand Therapy' />
                <meta property='og:url' content={ `https://www.herefordshirehandtherapy.co.uk/#/${url}` } />
                <meta property='og:image' content='https://www.herefordshirehandtherapy.co.uk/logo.png' />
                <meta property='og:image:width' content='106' />
                <meta property='og:image:height' content='114' />
                <meta name='description' content={ description } /> 
            </Helmet>
        );
    }
}

export default Seo;
