import React from 'react';
import Seo from '../components/Seo';
import ScrollToTopOnMount from '../components/ScrollToTop';
import '../css/header.css';
// import EmailForm from '../components/EmailForm';

const Contact: React.FC = () => {
  const title = 'Contact Information - Herefordshire Hand Therapy';
  const description =
    'Herefordshire Hand Therapy - Independent Occupational Therapist, Appointments and Contact information.';
  return (
    <div className='page-wrapper'>
      <ScrollToTopOnMount />
      <Seo title={title} description={description} url='contact' />
      <div className='content-wrapper'>
        <h1>Appointments</h1>
        <p>
          To make an appointment or to make a query, please use one of the
          following contact methods:
        </p>
        <p>
          Call:{' '}
          <a className='link' href='tel:+447872919826'>
            +447872919826
          </a>
        </p>
        <p>
          e-mail:{' '}
          <a
            className='link'
            href='mailto:info@herefordshirehandtherapy.co.uk'
            target='_blank'
            rel='noopener noreferrer'
          >
            info@herefordshirehandtherapy.co.uk
          </a>
        </p>
        <p>
          Face-to-face appointments currently only available on Tuesday mornings
          8.30–12.30.
        </p>
        <p>From early April 2024 appointment availability will be increased.</p>
        <p>Initial appointments will usually be offered within 1 week.</p>
        <p>Home visits could be arranged, if required.</p>
        <div className='contact-addresses'>
          <address className='contact-address'>
            <h2>Hereford consulting rooms</h2>
            <p>
              Kyrle House Practice
              <br />
              Kyrle Street
              <br />
              Hereford
              <br />
              HR1 2EU
            </p>
            <p>
              Tel: 01432 273234 (reception is available Mon–Fri 8.30–18.00 and
              Saturday 8.30–13.00)
            </p>
            <p>
              <strong>
                Face-to-face appointments currently available on Tuesday
                mornings 8.30–12.30.
              </strong>
            </p>
            <p>
              Free parking is available for the duration of your appointment.
            </p>
          </address>
          <address className='contact-address'>
            <h2>Correspondence address</h2>
            <p>
              24 Mostyn Street
              <br />
              Hereford
              <br />
              Herefordshire
              <br />
              HR4 0EG
            </p>
          </address>
        </div>
        {/* <EmailForm /> */}
      </div>
    </div>
  );
};

export default Contact;
