import React from 'react';
import Seo from '../components/Seo';
import ScrollToTopOnMount from '../components/ScrollToTop';
import Collapsible from '../components/Collapsible';
import '../css/services.css';
import staticSplint from '../images/static.png';
import dynamicSplint from '../images/dynamic.png';
import serialStatic1Splint from '../images/serial_static_1.png';
import serialStatic2Splint from '../images/serial_static_2.png';
import staticProgressiveSplint from '../images/static_progressive.png';

const Services: React.FC = () => {
  const title = 'Services and Treatments - Herefordshire Hand Therapy';
  const description =
    'Herefordshire Hand Therapy - Independent Occupational Therapist, Postoperative management, Injury prevention, Hand and upper limb assessment and treatment, Teaching sessions for trainee doctors, surgeons and occupational health practitioners. Treats Hand Trauma, Postoperative surgical conditions.';
  return (
    <div className='page-wrapper'>
      <ScrollToTopOnMount />
      <Seo title={title} description={description} url='services' />
      <div className='content-wrapper'>
        <h1>Services</h1>
        <p>
          We assess and manage patients following upper limb surgery and we
          provide conservative management of hand problems, to improve hand
          function.
        </p>
        <p>We use a variety of treatment techniques including:</p>
        <Collapsible title='Splinting'>
          <p>
            We can provide prefabricated splints or make customised splints
            specific to each patient's requirements.
          </p>
          <h3>Why do we splint?</h3>
          <p>
            <strong>Therapeutic</strong> splints provide pain relief and support
            to inflamed tissue.
          </p>
          <p>
            <strong>Prevent, correct or reduce risk of deformities</strong> from
            developing in a non-functional position.
          </p>
          <p>
            <strong>
              Promote function and compensate for loss of function.
            </strong>{' '}
            Splints can stabilize inflamed or deformed joints & minimize pain to
            allow greater power to be transmitted through joints.
          </p>
          <p>
            <strong>Post-surgical positioning and stabilisation</strong>{' '}
            protects healing structures and minimize post-surgical adhesions.
          </p>
          <h3>Types of splint</h3>
          <Collapsible title='Static'>
            <div className='splint-description'>
              <div className='splint-text'>
                <ul>
                  <li>Rest tissues</li>
                  <li>Provide support</li>
                  <li>Maintain range of motion</li>
                </ul>
              </div>
              <img
                src={staticSplint}
                alt='Static splint'
                className='splint-image'
              />
            </div>
          </Collapsible>
          <Collapsible title='Dynamic'>
            <div className='splint-description'>
              <div className='splint-text'>
                <ul>
                  <li>
                    Provide a constant force to the joint, even as motion
                    improves
                  </li>
                  <li>
                    Outrigger and traction devices on splints protect healing
                    tissues
                  </li>
                  <li>E.g. following joint replacement surgery</li>
                </ul>
              </div>
              <img
                src={dynamicSplint}
                alt='Dynamic splint'
                className='splint-image'
              />
            </div>
          </Collapsible>
          <Collapsible title='Serial static'>
            <div className='splint-description'>
              <div className='splint-text'>
                <ul>
                  <li>
                    Moulded in stationary position with tissues at maximum
                    length to increase range of motion
                  </li>
                  <li>
                    Changed frequently to accommodate reduced tissue resistance
                    as it adapts to new position
                  </li>
                  <li>E.g. following Dupuytren’s excision</li>
                </ul>
              </div>
              <div>
                <img
                  src={serialStatic1Splint}
                  alt='Serial static splint'
                  className='splint-image'
                />
                <img
                  src={serialStatic2Splint}
                  alt='Serial static splint'
                  className='splint-image'
                />
              </div>
            </div>
          </Collapsible>
          <Collapsible title='Static progressive'>
            <div className='splint-description'>
              <div className='splint-text'>
                <ul>
                  <li>
                    May be identical to dynamic splint but force is
                    static/inelastics
                  </li>
                  <li>
                    The client can adjust the tension in small increments, as
                    he/she is able to tolerate the pressure / within pain
                    tolerance
                  </li>
                  <li>
                    E.g. Flexion strap to promote flexion in a stiff joint
                  </li>
                </ul>
              </div>
              <img
                src={staticProgressiveSplint}
                alt='Static Progressive splint'
                className='splint-image'
              />
            </div>
          </Collapsible>
          <p></p>
        </Collapsible>
        <Collapsible title='Exercise programmes'>
          <p>
            Pain, stiffness and joint swelling can reduce range of movement,
            muscle strength and hand function.
          </p>
          <p>
            Swelling is natural in the first week after injury or surgery to the
            hand. A graded, evidence-based exercise programme can help to reduce
            swelling, lessen pain, increase flexibility and facilitate recovery.
            As healing progresses, passive range of movement and strengthening
            exercises may be introduced.
          </p>
          <p>
            Later in the rehabilitation process the hand therapist is likely to
            adopt a functional rehabilitative approach by introducing more
            strenuous self‐care, domestic and work duties. Return‐to‐work
            interventions may be advocated.
          </p>
          <p>
            In some long-term problems and conditions, muscle weakness and
            wasting is also common. In conditions such as inflammatory
            arthritis, disease modifying drugs are used to control disease
            activity. Muscle function is not spontaneously regained when disease
            control is gained with medication. A tailored strengthening and
            stretching hand exercise programme is therefore a standard component
            of self-management of long-term conditions.
          </p>
        </Collapsible>
        <Collapsible title='Mobilisations'>
          <p>
            Wrist and Hand mobilisation aims to restore normal joints range of
            motion to facilitate hand function. The hand therapist will consider
            stage of healing and evidence available for the most effective joint
            mobilisation technique and beneficial functional outcomes.
          </p>
        </Collapsible>
        <Collapsible title='Scar management'>
          <p>
            Scar formation following injury, burn, or surgery is part of your
            body’s normal healing process. It is a mixture of blood vessels,
            cells and fibrous tissue. Factors such as time passed since
            injury/surgery, the extent of your injury, size of surgical incision
            ang general health can influence the amount of scar tissue formed.
          </p>
          <p>
            Hand therapy can help to improve the aesthetic appearance of the
            scar and help to prevent/limit adhesions (prevent the scar from
            sticking to the body tissues underneath it, which can limit
            movement). It also helps to reduce redness and swelling and speeds
            up the flattening of the scar. Scar maturation can take up to two
            year; hand therapy can influence the organisation of collagen fibres
            in this remodelling phase of wound healing, in the following ways:
          </p>
          <ul>
            <li>
              Scar massage: Use a non-perfumed cream such as
              E45/cetraben/epaderm/aqueous cream on your scar. This will help to
              reduce the amount of dry skin, soften your scar and flatten
              hypertrophic scars. The hand therapist will advise you on the
              frequency, duration and technique. Firm deep massage in circular
              motions across stretches the skin in all directions to reduce the
              tightness of the skin while it is healing. Friction or suction to
              the scar may soften the scar and decrease the sticking process.
            </li>
            <li>
              Silicone sheets/pads/gels and pressure garments can also be useful
              to hydrate and flatten raised/hypertrophic scars. If your scar is
              irregularly shaped and/or deep, a silicone-based elastomer can be
              moulded to your scar.
            </li>
            <li>
              Commercially available or bespoke pressure garments to assist in
              preventing hypertrophic scars or lymphoedema are available at
              Herefordshire Hand Therapy.
            </li>
            <li>
              Kinesiotape uses gentle forces to aid scar remodelling to flatten
              raised scars, lift depressed scars, improve gliding of the tissue
              and reduce the occurrence of widening by reducing tension along
              the scar.
            </li>
          </ul>
        </Collapsible>
        <Collapsible title='Desensitisation programmes'>
          <p>
            Different textures and massage may help to decrease sensitivity of
            the scar.
          </p>
        </Collapsible>
        <Collapsible title='Management of swelling in the hand'>
          <p>
            Swelling following surgery or an injury can be part of a natural
            process, but it can also be part of a disease process. Compression,
            elevation, massage, use of kinesiotape and cooling techniques can
            assist in decreasing swelling levels.
          </p>
        </Collapsible>

        <Collapsible title='Joint protection and energy conservation techniques'>
          <p>
            Joint protection is a proven strategy to help you manage arthritis
            pain and perform daily activities more easily. Arthritic joints
            can't tolerate as much stress as healthy joints can, so pushing,
            pulling or twisting motions can be painful. Think about ways you can
            avoid unnecessarily stressing your joints.
          </p>
          <p>
            Joint protection doesn’t mean you should stop using your joints,
            just that you should use them differently. Try the following
            techniques to protect your joints:
          </p>
          <ul>
            <li>
              Take notice of any pain you feel – it can serve as a warning.
            </li>
            <li>
              Spread the weight over several joints when carrying something.
            </li>
            <li>
              Reduce the effort you have to put in – labour-saving gadgets can
              be a great help.
            </li>
            <li>Avoid gripping things tightly.</li>
            <li>Avoid positions that push your joints towards deformity.</li>
            <li>Use your joints in more stable positions.</li>
            <li>
              Stop to think if you could do something differently next time if
              it hurts you.
            </li>
          </ul>
          <p>
            Research has shown that joint protection methods help to reduce pain
            and make everyday activities easier. People with inflammatory
            arthritis have also reported less stiffness in the morning and fewer
            flare-ups when they use these techniques regularly. Energy
            conservation techniques can also be instructed so that individuals
            with long-term conditions have the required energy to perform tasks
            that they enjoy rather than exhausting themselves with tasks of
            daily living. Splinting can also be a very important treatment
            modality for this client group.
          </p>
        </Collapsible>

        <div className='collapsible'>
          <strong>Sensory re-education</strong>
        </div>
        <div className='collapsible'>
          <strong>Pain management</strong>
        </div>
        <div className='collapsible'>
          <strong>Wound care</strong>
        </div>

        {/* <h3>We also offer</h3>

        <p>
          Workplace assessments to identify barriers to participation in
          meaningful work. We aim to assist employers and employees following
          periods of absence, explore new roles, suggest workstation
          modification and consider reasonable accommodation to remain at work.
        </p>
        <p>
          Teaching sessions for various health professionals, including trainee
          doctors, allied health professional students and occupational health
          practitioners.
        </p> */}
        <h1>Conditions Treated</h1>
        <ul>
          <li className='li-h2'>
            <h2>Hand trauma</h2>
            <ul>
              <li>Fractures</li>
              <li>Dislocations and ligament injuries</li>
              <li>Nerve injuries</li>
              <li>
                Flexor and extensor tendon closed injuries, repairs and
                transfers
              </li>
              <li>Soft tissue injuries</li>
              <li>Pain syndromes</li>
              <li>Multi-trauma patients</li>
              <li>Crush injuries</li>
              <li>Scar adhesions and hypersensitivity</li>
              <li>Oedema (swelling)</li>
            </ul>
          </li>
          <li className='li-h2'>
            <h2>Postoperative surgical conditions</h2>
            <ul>
              <li>Dupuytren’s fasciectomy and Collagenase injection</li>
              <li>Carpal tunnel decompression</li>
              <li>Tendon transfers</li>
              <li>Trapeziectomy</li>
              <li>Metacarpo-phalangeal joint replacement surgery</li>
              <li>Proximal Interphalangeal joint replacement surgery</li>
              <li>Joint Fusions</li>
            </ul>
          </li>
          <li className='li-h2'>
            <h2>Other Conditions</h2>
            <ul>
              <li>Carpal Tunnel Syndrome</li>
              <li>Trigger Finger</li>
              <li>De Quervain’s Syndrome</li>
              <li>Osteoarthritis</li>
              <li>
                Inflammatory arthritis e.g. Rheumatoid Arthritis, Psoriatic
                arthritis and Lupus
              </li>
              {/* <li>Paediatric Congenital Hand Anomalies both before and after surgery</li> */}
              <li>Complex regional pain syndrome</li>
              <li>Tendonopathies</li>
              <li>Wrist pain</li>
              <li>Burns</li>
              <li>Benign Joint Hypermobility Syndrome (BJHS)</li>
            </ul>
          </li>
        </ul>
        <p>
          These are some of the most common conditions, although there are a
          wide variety of and conditions which we also manage on a regular
          basis.
        </p>
      </div>
    </div>
  );
};

export default Services;
