import staticSplint from '../images/static.png'
import dynamicSplint from '../images/dynamic.png'
import putty from '../images/putty_sml.png'
import serialStatic1Splint from '../images/serial_static_1.png'
import serialStatic2Splint from '../images/serial_static_2.png'
import staticProgressiveSplint from '../images/static_progressive.png'

export interface FlashCard {
  image?: string;
  question?: string;
  answer: string;
}

const Cards: FlashCard[] = [
  {
    "image": dynamicSplint,
    "answer": "Right hand"
  },
  {
    "image": putty,
    "answer": "Right hand"
  },
  {
    "image": serialStatic1Splint,
    "answer": "Left hand"
  },
  {
    "image": staticProgressiveSplint,
    "answer":"Right hand"
  },
  {
    "image": serialStatic2Splint,
    "answer": "Left hand"
  },
  {
    "image": staticSplint,
    "answer": "Left hand"
  },
]

export default Cards;