import React, { useCallback, useState } from 'react';
import Seo from '../components/Seo';
import ScrollToTopOnMount from '../components/ScrollToTop';
import InventoryTable from '../components/InventoryTable';
import { Item } from '../data/Inventory';
import InvoiceTable from '../components/InvoiceTable';

const Invoices: React.FC = () => {
  const [invoice, setInvoice] = useState<Item[]>([]);

  const title = 'Invoices - Herefordshire Hand Therapy';
  const description =
    'Herefordshire Hand Therapy - Independent Occupational Therapist, Postoperative management, Injury prevention, Hand and upper limb assessment and treatment, Teaching sessions for trainee doctors, surgeons and occupational health practitioners. Treats Hand Trauma, Postoperative surgical conditions.';

  const handleAddItem = (item: Item) => {
    setInvoice((oldArray) => [...oldArray, item]);
  };
  const handleRemoveItem = (item: Item) => {
    setInvoice((oldArray) => oldArray.filter((temp) => temp.id !== item.id));
  };

  const handleClearInvoice = useCallback(() => {
    setInvoice([]);
  }, []);

  return (
    <div className='page-wrapper'>
      <ScrollToTopOnMount />
      <Seo title={title} description={description} url='invoices' />
      <div className='content-wrapper'>
        <InventoryTable addItem={handleAddItem} />
        <div
          style={{
            width: '100%',
            height: '1px',
            backgroundColor: 'grey',
            margin: '8px',
          }}
        ></div>
        <InvoiceTable
          invoice={invoice}
          removeItem={handleRemoveItem}
          clearInvoice={handleClearInvoice}
        />
      </div>
    </div>
  );
};

export default Invoices;
