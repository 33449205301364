import React from "react";
import ScrollToTopOnMount from "../components/ScrollToTop";

const Links: React.FC = () => {
	return (
		<div className='page-wrapper'>
			<ScrollToTopOnMount/>
			Links
		</div>
	);
};

export default Links;
