import React from 'react';
import '../css/index.css';

const Footer: React.FC = () => {
  return (
    <div className='footer'>
      <div>
        <div>
          <p>
            <a className='link' href='tel:+447872919826'>
              +447872919826
            </a>
          </p>
          <p>
            <a
              className='link'
              href='mailto:info@herefordshirehandtherapy.co.uk'
              target='_blank'
              rel='noopener noreferrer'
            >
              info@herefordshirehandtherapy.co.uk
            </a>
          </p>
        </div>
        <div>
          <p>
            Copyright © 2024 Herefordshire Hand Therapy - All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
