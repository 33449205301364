import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../images/logo.min.svg';
import '../css/mobile-header.css';

const MobileHeader: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const burgerClick = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`mobile-header${menuOpen ? ' change' : ''}`}>
      <div className={`hamburger-container`} onClick={burgerClick}>
        <div className='bar1'></div>
        <div className='bar2'></div>
        <div className='bar3'></div>
      </div>
      <div className='mobile-header-title'>
        Herefordshire Hand Therapy
        <br />
        <small>Independent Occupational Therapist</small>
      </div>
      <div className={`mobile-header-links`}>
        <NavLink
          to='/'
          className='header-link'
          activeClassName='header-link-active'
          onClick={burgerClick}
          exact
        >
          Home
        </NavLink>
        <NavLink
          to='/therapist'
          className='header-link'
          activeClassName='header-link-active'
          onClick={burgerClick}
        >
          Therapist
        </NavLink>
        <NavLink
          to='/services'
          className='header-link'
          activeClassName='header-link-active'
          onClick={burgerClick}
        >
          Services
        </NavLink>
        <NavLink
          to='/contact'
          className='header-link'
          activeClassName='header-link-active'
          onClick={burgerClick}
        >
          Contact
        </NavLink>
      </div>
      <img
        src={logo}
        alt='Hereford Hand Therapy'
        className='mobile-header-logo'
      />
    </header>
  );
};

export default MobileHeader;
