import React, { useEffect, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table';
import { Item } from '../data/Inventory';

type InvoiceTableProps = {
  removeItem: (item: Item) => void;
  clearInvoice: () => void;
  invoice: Item[];
};

const InvoiceTable: React.FC<InvoiceTableProps> = (
  props: InvoiceTableProps
) => {
  const { removeItem, clearInvoice, invoice } = props;
  const [showRemoveButton, setShowRemoveButton] = useState(true);
  const [total, setTotal] = useState(0);

  const toggleRemove = () => setShowRemoveButton(!showRemoveButton);

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  const columns = React.useMemo<ColumnDef<Item, any>[]>(
    () => {
      const cols: ColumnDef<Item, any>[] = [
        {
          accessorKey: 'id',
          header: 'ID',
        },
        {
          accessorKey: 'type',
          header: 'Type',
        },
        {
          accessorKey: 'name',
          header: 'Name',
        },
        {
          accessorKey: 'size',
          header: 'Size',
        },

        {
          accessorKey: 'quantity',
          header: () => <div style={{ textAlign: 'right' }}>Qty</div>,
          cell: (info) => (
            <div style={{ textAlign: 'right' }}>{info.getValue()}</div>
          ),
        },

        {
          accessorKey: 'cost',
          header: () => <div style={{ textAlign: 'right' }}>Cost</div>,
          cell: (info) => (
            <div style={{ textAlign: 'right' }}>
              {formatter.format(
                info.getValue() * (info.row.original.quantity || 1)
              )}
            </div>
          ),
        },
      ];
      if (showRemoveButton) {
        cols.push({
          header: 'Remove',
          cell: (info) => (
            <button onClick={() => removeItem(info.row.original)}>
              Remove
            </button>
          ),
        });
      }
      return cols;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showRemoveButton]
  );

  const data = invoice.reduce<Item[]>((acc, item) => {
    if (acc.some((thing) => item.id === thing.id)) {
      return acc.map((thing) => {
        if (thing.id === item.id) {
          return {
            ...thing,
            quantity: thing.quantity ? thing.quantity + 1 : 1,
          };
        }
        return thing;
      });
    } else {
      acc.push({ ...item, quantity: 1 });
    }
    return acc;
  }, []);

  useEffect(
    () =>
      setTotal(
        data.reduce<number>((acc, item) => {
          const qty = item.quantity || 1;
          acc = acc + item.cost * qty;
          return acc;
        }, 0)
      ),
    [data]
  );

  const table = useReactTable({
    data,
    columns,
    state: {},
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <div className='p-2'>
      <div>Invoice</div>
      <button onClick={clearInvoice}>Clear Invoice</button>
      <button onClick={toggleRemove}>Toggle remove buttons</button>
      <table style={{ width: '100%' }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        style={{
          textAlign: 'right',
          paddingRight: showRemoveButton ? '110px' : '4px',
        }}
      >
        Total: {formatter.format(total)}
      </div>
      <div className='h-2' />
    </div>
  );
};

export default InvoiceTable;
