import React from 'react';
import Seo from '../components/Seo';
import ScrollToTopOnMount from '../components/ScrollToTop';
import Suzette from '../images/Suzette.jpg'

const Therapist: React.FC = () => {
	const title = 'Independent Occupational Therapist - Herefordshire Hand Therapy';
	const description = 'Herefordshire Hand Therapy - Independent Occupational Therapist, Suzette Jones is a Specialist Occupational Therapist in Hand Therapy.';
	return (
		<div className='page-wrapper'>
			<ScrollToTopOnMount/>
			<Seo title={ title } description={description}  url='therapist'/>
			<div className='content-wrapper'>
				<p>About Herefordshire Hand Therapy – Independent Occupational Therapist</p>
				<h1>Therapist</h1>
				<div className='therapist-description'>
					<img src={Suzette} alt='Suzette Jones' className='splint-image'/>
					<div className='therapist-text'>
						<p><strong>Suzette Jones (B. Occ T (HONS)) is a Specialist Occupational Therapist in Hand Therapy.</strong></p>
					</div>
				</div>
				<h3>About Suzette</h3>
				<p>Suzette established Herefordshire Hand Therapy with the aim of providing evidence based assessment and treatment of all hand and upper limb conditions, in a timely manner, to assist clients in returning to meaningful vocation.</p>
				<p>She has been working in the specialist area of Hand Therapy since 2002, after she qualified as an occupational therapist in South Africa - B. Occ T Pret (HONS), 2002.</p>
				<p>She gained valuable experience in South Africa (2002-2004) in paediatrics, neurology, neurosurgery, spinal rehab, burns, plastic surgery, hands and rheumatology.</p>
				<p>A post graduate diploma in vocational rehabilitation in 2004, has equipped her for functional capacity evaluations and work hardening.</p>
				<p>In 2005-2006 she started working in the UK as a locum within surgery, stroke rehabilitation, hand therapy and rheumatology. She joined Wye Valley NHS Trust in 2006, as a specialist occupational therapist in hands & rheumatology and has been managing this small outpatient Hand therapy team since.</p>
				<p>Her clinical experience has focused on trauma and elective orthopaedic surgery patients with upper limb injuries and rheumatological conditions. Her competence static and dynamic splinting, is a sought after treatment modality in rural Herefordshire. Qualified to administer upper limb corticosteroid injections and have achieved competency at British Association of Hand Therapists (BAHT) Level III.</p>
			</div>
		</div>
	);
};

export default Therapist;
