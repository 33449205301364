export enum ItemType {
  digital = 'Digital Based',
  hand = 'Hand Based',
  forearm = 'Forearm Based',
  other = 'Other',
  dressings = 'Dressings',
  report = 'Report',
  consultation = 'Consultation',
}

export enum Size {
  short = 'Short',
  long = 'Long',
  large = 'Large',
  small = 'Small',
  medium = 'Medium',
  fiveBySeven = '5cm x 7cm',
  fiveBySevenPointTwo = '5cm x 7.2cm',
  tenByTen = '10cm x 10cm',
  tenByEight = '10cm x 8cm',
  threeBySeventyFive = '3mm x 75mm',
  fiveByFourMetre = '5cm x 4m',
  twelveByFifteen = '12cm x 15cm',
  fourByFive = '4cm by 5cm',
  fourByFifteen = '4cm by 1.5m',
}

export interface Item {
  id: string;
  type: ItemType;
  name: string;
  description: string;
  cost: number;
  size?: Size;
  quantity?: number;
}

const Inventory: Item[] = [
  {
    id: 'dig001',
    type: ItemType.digital,
    name: 'Oval-8 splint',
    description: 'Oval-8 splint',
    cost: 11,
  },
  {
    id: 'dig002',
    type: ItemType.digital,
    name: 'Bespoke Neoprene digisleeve',
    description: 'Bespoke Neoprene digisleeve (short)',
    cost: 5,
    size: Size.short,
  },
  {
    id: 'dig003',
    type: ItemType.digital,
    name: 'Bespoke Neoprene digisleeve',
    description: 'Bespoke Neoprene digisleeve (long)',
    cost: 8,
    size: Size.long,
  },
  {
    id: 'dig004',
    type: ItemType.digital,
    name: 'Bespoke Thermoplastic gutter splint',
    description: 'Bespoke Thermoplastic gutter splint',
    cost: 8,
  },
  {
    id: 'dig005',
    type: ItemType.digital,
    name: 'Rolyan Sof-Stretch Extension Splint',
    description: 'Rolyan Sof-Stretch Extension Splint',
    cost: 15,
  },
  {
    id: 'dig006',
    type: ItemType.digital,
    name: 'Single Armchair splint',
    description: 'Single Armchair splint',
    cost: 20,
  },
  {
    id: 'han001',
    type: ItemType.hand,
    name: 'Thermoplastic static progressive splint',
    description:
      'Thermoplastic static progressive/serial static splint (large hand-based)',
    cost: 25,
    size: Size.large,
  },
  {
    id: 'han002',
    type: ItemType.hand,
    name: 'Thermoplastic serial static splint',
    description:
      'Thermoplastic static progressive/serial static splint (large hand-based)',
    cost: 25,
    size: Size.large,
  },
  {
    id: 'han003',
    type: ItemType.hand,
    name: 'Thermoplastic thumb CMC splint',
    description: 'Thermoplastic thumb CMC splint  (small hand-based)',
    cost: 20,
    size: Size.small,
  },
  {
    id: 'han004',
    type: ItemType.hand,
    name: 'Push splint',
    description: 'Push splint',
    cost: 52,
  },
  {
    id: 'han005',
    type: ItemType.hand,
    name: 'Actimove® Rhizo Forte thumb brace',
    description: 'Actimove® Rhizo Forte thumb brace (£30 plus postage (£35))',
    cost: 35,
  },
  {
    id: 'han006',
    type: ItemType.hand,
    name: 'Isotoner gloves (pair)',
    description: 'Isotoner gloves (pair) (self pay)',
    cost: 24,
  },
  {
    id: 'han007',
    type: ItemType.hand,
    name: 'Isotoner gloves (pair)',
    description: 'Isotoner gloves (pair) (Nuffield)',
    cost: 30,
  },
  {
    id: 'han008',
    type: ItemType.hand,
    name: 'Thermoskin gloves',
    description: 'Thermoskin gloves (plus postage)',
    cost: 29,
  },
  {
    id: 'for001',
    type: ItemType.forearm,
    name: 'Thermoplastic static splint',
    description: 'Forearm-based static (e.g., wrist extension splint)',
    cost: 45,
  },
  {
    id: 'for002',
    type: ItemType.forearm,
    name: 'Thermoplastic static progressive splint',
    description:
      'Forearm-based static progressive 2nd - 5th MCP joint flexion exercise splint',
    cost: 60,
  },
  {
    id: 'for003',
    type: ItemType.forearm,
    name: 'Thermoplastic static splint',
    description: 'Forearm-based static NRS (including hand and wrist)',
    cost: 60,
  },
  {
    id: 'for004',
    type: ItemType.forearm,
    name: 'Thermoplastic serial static splint',
    description: 'Serial static elbow extension splint',
    cost: 70,
  },
  {
    id: 'for005',
    type: ItemType.forearm,
    name: 'Thermoplastic dynamic splint',
    description: 'Forearm-based dynamic MCP extension splint',
    cost: 85,
  },
  {
    id: 'for006',
    type: ItemType.forearm,
    name: 'Procool thumb/wrist restriction splints - working splints',
    description: 'Procool thumb/wrist restriction splints - working splints',
    cost: 38,
  },
  {
    id: 'for007',
    type: ItemType.forearm,
    name: 'Procool thumb restriction splint / Benecare thumb CMC splint',
    description: 'Procool thumb restriction splint / Benecare thumb CMC splint',
    cost: 30,
  },
  {
    id: 'for008',
    type: ItemType.forearm,
    name: 'Pro-Rheuma wrist thumb brace',
    description: 'Pro-Rheuma wrist thumb brace',
    cost: 30,
  },
  {
    id: 'for009',
    type: ItemType.forearm,
    name: 'KSA Neoprene Wrist Brace / Economy Standard Neo Brace Black 3mm',
    description:
      'KSA Neoprene Wrist Brace / Economy Standard Neo Brace Black 3mm',
    cost: 12,
  },
  {
    id: 'for010',
    type: ItemType.forearm,
    name: 'Nustimwrap thumb/wrist brace',
    description: 'Nustimwrap thumb/wrist brace',
    cost: 18,
  },
  {
    id: 'oth001',
    type: ItemType.other,
    name: 'Tennis elbow cuff',
    description: 'Tennis elbow cuff (plus postage)',
    cost: 12,
  },
  {
    id: 'oth002',
    type: ItemType.other,
    name: 'Procool open elbow brace',
    description: 'Procool open elbow brace',
    cost: 28,
  },
  {
    id: 'oth003',
    type: ItemType.other,
    name: 'TFCC wrist cuff',
    description: 'TFCC wrist cuff',
    cost: 15,
  },
  {
    id: 'oth004',
    type: ItemType.other,
    name: 'Theraputty',
    description: 'Theraputty (£5 vs £8)',
    cost: 8,
  },
  {
    id: 'oth005',
    type: ItemType.other,
    name: '1m Theraband',
    description: '1m Theraband',
    cost: 5,
  },
  {
    id: 'oth006',
    type: ItemType.other,
    name: 'Temper Foam R-Lite Foam Blocks',
    description: 'Temper Foam R-Lite Foam Blocks, Soft (pink / firm (green)',
    cost: 2,
  },
  {
    id: 'oth007',
    type: ItemType.other,
    name: '3M Coban Self-Adherent Wrap, 1 Inch',
    description: '3M Coban Self-Adherent Wrap, 1 Inch',
    cost: 5,
  },
  {
    id: 'oth008',
    type: ItemType.other,
    name: 'Alternate Self-Adherent Wrap, 1 Inch',
    description: 'Alternate Self-Adherent Wrap, 1 Inch',
    cost: 2,
  },
  {
    id: 'dre001',
    type: ItemType.dressings,
    name: 'Sterile dressing pack',
    description: 'Sterile dressing pack',
    cost: 2,
    size: Size.small,
  },
  {
    id: 'dre002',
    type: ItemType.dressings,
    name: 'Sterile dressing pack',
    description: 'Sterile dressing pack',
    cost: 2,
    size: Size.medium,
  },
  {
    id: 'dre003',
    type: ItemType.dressings,
    name: 'Disposable Sterile supasnips scissors',
    description:
      'Disposable Sterile supasnips scissors (x15 £5.42 + £7 postage)',
    cost: 1,
  },
  {
    id: 'dre004',
    type: ItemType.dressings,
    name: '20ml Irripod saline irrigation solution',
    description: '20ml Irripod saline irrigation solution',
    cost: 1.5,
  },
  {
    id: 'dre005',
    type: ItemType.dressings,
    name: 'Mepitel Dressing',
    description: 'Mepitel Dressing 5cm x 7cm ( x5 £13.09 plus postage £6.99)',
    cost: 4,
    size: Size.fiveBySeven,
  },
  {
    id: 'dre006',
    type: ItemType.dressings,
    name: 'URGOTUL Absorb border dressing',
    description: 'URGOTUL Absorb border dressing 10 cm x 10 cm',
    cost: 6,
    size: Size.tenByTen,
  },
  {
    id: 'dre007',
    type: ItemType.dressings,
    name: 'Cosmopor E Dressing',
    description:
      'Cosmopor E Dressing- 7.2cm x 5cm (x50 £8.42 plus postage £6.99)',
    cost: 1,
    size: Size.fiveBySevenPointTwo,
  },
  {
    id: 'dre008',
    type: ItemType.dressings,
    name: 'Cosmopor E Dressing',
    description:
      'Cosmopor E Dressing- 10cm x 8cm (x25 £7.24 plus postage £6.99)',
    cost: 1.5,
    size: Size.tenByEight,
  },
  {
    id: 'dre009',
    type: ItemType.dressings,
    name: 'Softpore Dressings',
    description: 'Softpore Dressings 10cm x 10cm',
    cost: 1.2,
    size: Size.tenByTen,
  },
  {
    id: 'dre010',
    type: ItemType.dressings,
    name: 'Steristrips Skin Closure Strips',
    description:
      'Steristrips Skin Closure Strips 3mm x 75mm x (50 Packs of 5 Strips £20 + £7 postage)',
    cost: 1,
    size: Size.threeBySeventyFive,
  },
  {
    id: 'dre011',
    type: ItemType.dressings,
    name: 'Wipes Wound Cleansing Sterile',
    description:
      'Wipes Wound Cleansing Sterile x 20 (Reliwipe) (£4 + £7 postage)',
    cost: 0.8,
  },
  {
    id: 'dre012',
    type: ItemType.dressings,
    name: 'Conforming bandage',
    description: 'Conforming bandage 5cm x 4m',
    cost: 0.8,
    size: Size.fiveByFourMetre,
  },
  {
    id: 'dre013',
    type: ItemType.dressings,
    name: 'Cica care silicone gel',
    description: 'Cica care silicone gel 12cm x 15cm',
    cost: 25,
    size: Size.twelveByFifteen,
  },
  {
    id: 'dre014',
    type: ItemType.dressings,
    name: 'Cica care silicone gel',
    description: 'Cica care silicone gel 4cm x 5cm',
    cost: 8,
    size: Size.fourByFive,
  },
  {
    id: 'dre015',
    type: ItemType.dressings,
    name: 'Medical grade silicone scar sheet (Elaimei)',
    description: 'Medical grade silicone scar sheet (Elaimei): 4cm x 1.5m',
    cost: 15,
    size: Size.fourByFifteen,
  },
  {
    id: 'dre016',
    type: ItemType.dressings,
    name: 'Medical grade silicone scar sheet (Elaimei)',
    description: 'Medical grade silicone scar sheet (Elaimei): 4cm x 5cm',
    cost: 4,
    size: Size.fourByFive,
  },
  {
    id: 'dre017',
    type: ItemType.dressings,
    name: 'Silicone digisleeve',
    description: 'Silicone digisleeve',
    cost: 2,
  },
  {
    id: 'dre018',
    type: ItemType.dressings,
    name: 'Silipos Digicap',
    description: 'Silipos Digicap',
    cost: 6,
  },
  {
    id: 'dre019',
    type: ItemType.dressings,
    name: 'M-Gel Digital Strip',
    description: 'M-Gel Digital Strip, Small (+postage)',
    cost: 1.5,
    size: Size.small,
  },
  {
    id: 'dre020',
    type: ItemType.dressings,
    name: 'M-Gel Digital Strip',
    description: 'M-Gel Digital Strip, Medium (+postage)',
    cost: 2,
    size: Size.medium,
  },
  {
    id: 'dre021',
    type: ItemType.dressings,
    name: 'Orfitubes',
    description: 'Orfitubes',
    cost: 4,
  },
  {
    id: 'dre022',
    type: ItemType.dressings,
    name: 'Orfitube ends',
    description: 'Orfitube ends',
    cost: 2,
  },
  {
    id: 'dre023',
    type: ItemType.dressings,
    name: 'Elastic Thread',
    description: 'Elastic Thread £12 per spool (10m) plus £1 delivery',
    cost: 1.3,
  },
  {
    id: 'dre024',
    type: ItemType.dressings,
    name: 'Slings with eyelets',
    description: 'Slings with eyelets',
    cost: 2,
  },
  {
    id: 'rep001',
    type: ItemType.report,
    name: 'Insurance Report',
    description: 'Progress report to the insurance company',
    cost: 80,
  },
  {
    id: 'con001',
    type: ItemType.consultation,
    name: 'Consultation',
    description: 'Time spent with patient',
    cost: 55,
  },
];

export default Inventory;
