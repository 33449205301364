import React, { useState } from "react";

type CollapsibleProps = {
  title: string;
}

const Collapsible: React.FC<CollapsibleProps> = (props: React.PropsWithChildren<CollapsibleProps>) => {

  const [collapsibleOpen, setCollapsibleOpen] = useState(false);

  const {title, children} = props;

	const togglePanel = () => {
    setCollapsibleOpen(!collapsibleOpen);
	}

		return (
		<div>
			<div className="collapsible" onClick={togglePanel}>{title} [{collapsibleOpen ? "-": "+"}]</div>
      <div className={collapsibleOpen ? 'slidedown' : 'slideup' }>
        {children}
      </div>
		</div >);
}

export default Collapsible;
